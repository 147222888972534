export const environment = {
  production: true,
  env: 'prod-euwest1',
  host: 'leapest.com',

  hedwigUrl: 'https://hedwig.leapest.com',
  saharaUrl: 'https://my.leapest.com',
  instructorMarketUrl: 'https://instructors.leapest.com',
  serviceMarketUrl: 'https://instructors.leapest.com',
  integrationFramework: 'https://integration.leapest.com',
  amberBaseUrl: 'https://amber.leapest.com',
  amberUrl: 'https://amber.leapest.com/api',
  bouncerUrl: 'https://account.leapest.com',
  sphinxUrl: 'https://downloads.leapest.com',

  welcomePageCampaignKey: 'product-marketplace.welcome-page.campaign',
  welcomePagePromotionKey: 'product-marketplace.welcome-page.promotion',

  aws: {
    key: 'AKIAQ6EHUBLEURAGLSUZ',
    s3Url: 'https://downloads.leapest.com/proxy/service/jwt',
    cdnBucket: 'leap-prod-euwest1-cdn',
    uploadsBucket: 'leap-prod-euwest1-product-marketplace-uploads',
  },

  adyen: {
    clientKey: 'live_AB6UGXNCEBENTI45JT5EAO4ZTYMXYGVV'
  },
  okta: {
    issuer: 'https://edcast.okta.com/oauth2/ausew5lg3ep73Kkol2p7',
    clientId: '00a-prod-env',
    prefix: 'prod'
  }
};
